thead {
    opacity: 1;
    color: #000000;
    border: none;
    border-radius: 0.375rem;
    box-shadow: none;
}

thead .thead-green {
    background: linear-gradient(195deg, #66BB6A , #43A047);
}

thead .thead-dark {
    background: linear-gradient(195deg, #212529, #212529);
}

thead .customGeneral {
    color: inherit;
    display: table-row;
    vertical-align: middle;
    outline: 0;
}

thead .customGeneral th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    text-align: left;
    background: transparent;
    color: #ffffff;
    border-radius: none;
    box-shadow: none;
    border-bottom: 0.0625rem solid #f0f2f5;
    font-size: 0.85rem;
    font-weight: 700;
    text-transform: uppercase;
}

tbody tr td {
    text-align: left;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    opacity: 1;
    background: transparent;
    color: #344767;
    border-radius: none;
    box-shadow: none;
    font-size: 1rem;
    border-bottom: 0.0625rem solid #f0f2f5;
    vertical-align: middle;
}

tbody .customSuplimentar td {
    vertical-align: middle;
    outline: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    text-align: center;
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
    color: #ffffff;
    border-radius: none;
    box-shadow: none;
    border-bottom: 0.0625rem solid #f0f2f5;
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
}

tbody .customNeprevazut td {
    vertical-align: middle;
    outline: 0;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    position: relative;
    text-align: center;
    background: linear-gradient(195deg, #FFA726, #FB8C00);;
    color: #ffffff;
    border-radius: none;
    box-shadow: none;
    border-bottom: 0.0625rem solid #f0f2f5;
    font-size: 0.8rem;
    font-weight: 700;
    text-transform: uppercase;
}

tbody tr.customCompleted {
    background: linear-gradient(195deg, hsl(123, 38%, 57%, 0.2), hsl(123, 41%, 45%, 0.2));
}

tbody tr.customCompleted td{
    text-decoration: line-through;
}

tbody tr.customCompleted td button {
    cursor: not-allowed !important;
    opacity: 0.5;
}

.form-check-input:checked {
    background-color: #e91e63;
    border-color: #e91e63;
}


.form-check-input:focus {
    border-color: rgb(233 32 99);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(233 32 99 / 25%);
}

.addDailyNotification .input-group,
.addInformationDetails .input-group,
.addInformationCategories .input-group {
    margin-bottom: 10px;
}

.addDailyNotification .form-check-input {
    margin-right: 10px;
}

.addDailyNotification .calendarWrap {
    margin-bottom: 10px;
}

.bg-primaryColor {
    background: linear-gradient(195deg, #EC407A, #D81B60);
    color: #ffffff;
}

.daily-notification-important-text {
    color: red !important;
}

.customMinDropdown {
    min-width: 100px;
}

#addTask .form-label {
    color: #ffffff;
}

td.form-action {
    vertical-align: bottom !important;
}

.customCompleted .form-check-input:checked {
    background-color: rgb(72 233 32);
    border-color: rgb(72 233 32);
}


.customCompleted .form-check-input:checked {
    border-color: rgb(72 233 32);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(72 233 32 / 25%);
}

.text-customPrimary {
    color: #e91e63;
}

.text-customPrimary:hover {
    background-color: transparent !important;
}

.bg-customTransparent {
    background-color: transparent !important;
}

.bg-customTransparent:hover,
.bg-customTransparent:active,
.bg-customTransparent:focus {
    background-color: transparent !important;
    border-color: transparent !important;
}
